<template>
  <div>
    <div class="my-10">
      <input
        type="text"
        placeholder="Enter company name to filter"
        class="border-2 w-full rounded-md px-4 py-3"
      />
    </div>
    <div class="relative overflow-x-auto" style="max-height: 63vh !important">
      <table
        class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-2 rounded-md"
      >
        <thead
          class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="p-4 hidden">
              <div class="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label for="checkbox-all-search" class="sr-only"
                  >checkbox</label
                >
              </div>
            </th>
            <th scope="col" class="px-6 py-3">SN</th>
            <th scope="col" class="px-6 py-3">Taxpayer name</th>
            <th scope="col" class="px-6 py-3">Year</th>
            <th scope="col" class="px-6 py-3">Returns Type</th>
            <th scope="col" class="px-6 py-3">Date filed</th>
            <th scope="col" class="px-6 py-3">Status</th>
            <th scope="col" class="px-6 py-3">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in returns"
            :key="item.id"
            @click="viewReturnsDetails(item)"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
          >
            <td class="w-4 p-4 hidden">
              <div class="flex items-center">
                <input
                  id="checkbox-table-search-1"
                  type="checkbox"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label for="checkbox-table-search-1" class="sr-only"
                  >checkbox</label
                >
              </div>
            </td>
            <td class="px-6 py-2">{{ index + 1 }}</td>
            <th
              scope="row"
              class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              <div class="">{{ item.taxpayer.name }}</div>
            </th>
            <td class="px-6 py-2">{{ item.returns_year }}</td>
            <td class="px-6 py-2">{{ item.returns_type }}</td>
            <td class="px-6 py-2">
              <div class="w-36">
                {{ formatDate(item.createdAt) }}
              </div>
            </td>
            <td class="px-6 py-2">
              <span
                :class="[
                  item.status == 'filed'
                    ? 'bg-yellow-200 text-yellow-600'
                    : 'bg-green-200 text-green-600',
                  'px-4 py-2 rounded-full font-semibold capitalize text-xs ',
                ]"
                >{{ item.status }}</span
              >
            </td>
            <td class="px-6 py-2">
              <a
                @click.prevent="viewReturnsDetails(item)"
                class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                >View</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <nav
      class="flex items-center flex-column md:flex-row justify-between pt-4 w-full"
      aria-label="Table navigation"
    >
      <span
        class="text-sm font-normal text-gray-500 dark:text-gray-400 block w-full md:inline md:w-auto"
        >Showing
        <span class="font-semibold text-gray-900 dark:text-white"
          >1-{{ returns.length }}</span
        >
        of
        <span class="font-semibold text-gray-900 dark:text-white">{{
          returns.length
        }}</span>
      </span>
      <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
        <li>
          <button
            disabled
            class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            Previous
          </button>
        </li>

        <li>
          <button
            disabled
            class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      returnsFilterValue: "",
      annualReturnsInterval: null,
    };
  },
  computed: {
    returns() {
      return this.$store.getters["annual_returns/getReturns"].filter((item) => {
        return item.taxpayer.name
          .toLowerCase()
          .includes(this.returnsFilterValue.toLocaleLowerCase());
      });
    },
  },
  methods: {
    formatDate(dt) {
      return moment(dt).format("MMMM Do YYYY, h:mm:ss a");
    },
    viewReturnsDetails(details) {
      this.$emit("view-returns", details);
    },
    async fetchReturnsFromDb() {
      await this.$store.dispatch(
        "annual_returns/getAnnualReturnsForAcknowledgment"
      );
    },
  },
  async created() {
    await this.fetchReturnsFromDb();
  },
  mounted() {
    this.annualReturnsInterval = setInterval(async () => {
      await this.fetchReturnsFromDb();
    }, 10000);
  },
  unmounted() {
    clearInterval(this.annualReturnsInterval);
  },
};
</script>

<style lang="scss" scoped></style>
