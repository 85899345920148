<template>
  <div>
    <ContentHolder v-if="canViewReturns" :title="'Annual Returns'" class="p-4">
      <AnnualReturnsList
        v-if="activeView === 'returns-list'"
        @view-returns="viewReturnsDetails"
      ></AnnualReturnsList>
      <AnnualReturnsDetails
        v-else
        @go-back="activeView = 'returns-list'"
        :details="returnsDetails"
      ></AnnualReturnsDetails>
    </ContentHolder>
    <content-holder v-else :title="'Annual Returns'" class="p-4">
      <unauthorized-access></unauthorized-access>
    </content-holder>
  </div>
</template>

<script>
import ContentHolder from "@/components/ContentHolder.vue";
import AnnualReturnsList from "@/components/annual_returns/AnnualReturnsList.vue";
import AnnualReturnsDetails from "@/components/annual_returns/AnnualReturnsDetails.vue";
import UnauthorizedAccess from "@/components/shared/UnauthorizedAccess.vue";
import { views } from "@/helpers/login_details.js";

export default {
  components: {
    ContentHolder,
    UnauthorizedAccess,
    AnnualReturnsList,
    AnnualReturnsDetails,
  },
  data() {
    return {
      activeView: "returns-list",
      canViewReturns: false,
      returnsDetails: {},
    };
  },
  methods: {
    viewReturnsDetails(details) {
      this.returnsDetails = details;
      this.activeView = "returns-details";
    },
  },
  created() {
    if (views.includes("AnnualReturns")) {
      this.canViewReturns = true;
    } else {
      this.canViewReturns = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
