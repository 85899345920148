<template>
  <div>
    <button @click="goBack" class="mt-4 text-gray-500 focus:outline-none">
      <i class="fas fa-angle-left"></i>
      Go Back
    </button>
    <teleport to=".modal">
      <authorize-modal
        title="Payment Receipt Information"
        v-show="showReceiptDetails"
        @hideModal="showReceiptDetails = false"
      >
        <div class="text-left border-t border-gray-100">
          <dl>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Name</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ receiptDetails.customer + "" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Amount</dt>
              <dd
                class="mt-1 text-lg text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                ₦ {{ formatAmount(receiptDetails.strippedAmount) }}
              </dd>
            </div>

            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Revenue Item</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ receiptDetails.revenue_item }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Revenue Category
              </dt>
              <dd
                class="mt-1 text-lg text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ receiptDetails.revenue_category }}
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Payment REF</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ receiptDetails.payment_ref }}
              </dd>
            </div>
          </dl>
        </div>
      </authorize-modal>
    </teleport>

    <div class="my-10">
      <input
        type="text"
        placeholder="Enter staff name to filter"
        class="border-2 w-full rounded-md px-4 py-3"
      />
    </div>

    <div class="w-full flex lg:flex-row lg:space-x-2 flex-col">
      <div
        class="lg:w-9/12 shadow bg-white rounded-md border mb-10 lg:mb-0 flex flex-col justify-between"
      >
        <div
          class="relative overflow-auto h-full"
          style="max-height: 65vh !important"
        >
          <table
            class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="p-4 hidden">
                  <div class="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label for="checkbox-all-search" class="sr-only"
                      >checkbox</label
                    >
                  </div>
                </th>
                <th scope="col" class="px-6 py-3">SN</th>
                <th scope="col" class="px-6 py-3">Staff name</th>
                <th scope="col" class="px-6 py-3">Total Income</th>
                <th scope="col" class="px-6 py-3">Months Worked</th>
                <th scope="col" class="px-6 py-3">Tax Relief(CRA)</th>
                <th scope="col" class="px-6 py-3">Total Deductions</th>
                <th scope="col" class="px-6 py-3">Tax Payable</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(payment, index) in paymentSchedule"
                :key="payment.id"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
              >
                <td class="w-4 p-4 hidden">
                  <div class="flex items-center">
                    <input
                      id="checkbox-table-search-1"
                      type="checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label for="checkbox-table-search-1" class="sr-only"
                      >checkbox</label
                    >
                  </div>
                </td>
                <td class="px-6 py-4">{{ index + 1 }}</td>
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ payment.employee_name }}
                </th>
                <td class="px-6 py-4">
                  ₦ {{ formatAmount(payment.total_income) }}
                </td>
                <td class="px-6 py-4">{{ payment.num_of_months_worked }}</td>
                <td class="px-6 py-4">
                  ₦ {{ formatAmount(payment.tax_relief_cra) }}
                </td>
                <td class="px-6 py-4">
                  ₦ {{ formatAmount(payment.total_deductions) }}
                </td>
                <td class="px-6 py-4">
                  ₦ {{ formatAmount(payment.tax_paid) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <nav
          class="flex items-center flex-column md:flex-row justify-between pt-4 w-full pl-4 pr-2 py-2"
          aria-label="Table navigation"
        >
          <span
            class="text-sm font-normal text-gray-500 dark:text-gray-400 block w-full md:inline md:w-auto"
            >Showing
            <span class="font-semibold text-gray-900 dark:text-white"
              >1-{{ paymentSchedule.length }}</span
            >
            of
            <span class="font-semibold text-gray-900 dark:text-white">{{
              paymentSchedule.length
            }}</span>
          </span>
          <ul
            class="inline-flex -space-x-px rtl:space-x-reverse text-sm font-medium h-8"
          >
            <li>
              <button
                disabled
                class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                Previous
              </button>
            </li>

            <li>
              <button
                disabled
                class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>

      <!-- Cover letter section -->
      <div
        class="lg:w-3/12 shadow bg-white rounded-md py-5 px-3 flex flex-col justify-between"
      >
        <div style="max-height: 55vh !important">
          <div class="cover-letter" v-if="details.cover_letter">
            <div class="flex flex-row justify-between items-center">
              <h1 class="text-sm text-gray-dark font-medium">Cover letter</h1>
              <button class="text-xs text-blue-500 font-medium">View</button>
            </div>
            <div
              class="flex justify-start space-x-2 items-center rounded-md border-2 border-gray-100 hover:bg-gray-50 cursor-pointer shadow-sm shadow-dark py-2 px-2 mt-2"
            >
              <div class="bg-purple-200 rounded-full p-1">
                <i
                  class="fas fa-file bg-purple-300 rounded-full text-purple-700 px-2.5 py-1.5 text-xs"
                ></i>
              </div>
              <div class="flex flex-col">
                <a
                  :href="details.cover_letter"
                  :download="details.cover_letter"
                  :title="details.cover_letter"
                  target="_blank"
                  class="text-xs font-medium text-gray-dark flex-wrap"
                  >{{ details.taxpayer.name }}</a
                >
                <span class="text-xs text-gray-500 hidden">200kb</span>
              </div>
            </div>
          </div>

          <!-- Receipts section -->
          <div
            class="receipts"
            :class="[details.cover_letter ? 'mt-6' : '']"
            v-if="paymentReceipts.length > 0"
          >
            <div class="flex flex-row justify-between items-center">
              <h1 class="text-sm text-gray-dark font-medium">
                Payment Receipts
              </h1>
              <button class="text-xs text-blue-500 font-medium">
                View all
              </button>
            </div>

            <div class="overflow-y-auto" style="max-height: 45vh !important">
              <div
                v-for="receipt in paymentReceipts"
                @click.prevent="
                  viewReceipt(receipt.split('|')[receipt.split('|').length - 1])
                "
                :key="receipt"
                class="flex flex-col justify-start items-start rounded-md hover:bg-gray-50 cursor-pointer border-2 border-gray-100 shadow-sm shadow-dark py-2 px-2 mt-2"
              >
                <span class="text-xs font-medium text-gray-dark">{{
                  receipt
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <button
          id="btnAcknowledge"
          type="button"
          @click.prevent="acknowledgeReturns"
          :disabled="acknowledging"
          class="w-full mt-10 px-8 py-2 font-semibold text-white text-lg transition duration-500 ease-in-out transform rounded-lg bg-gradient-to-r from-blue-700 hover:from-blue-600 to-blue-600 hover:to-blue-700 focus:ring focus:ring-blue-700 focus:outline-none"
        >
          <div v-if="!acknowledging">Acknowledge</div>
          <div v-else class="flex items-center space-x-4 justify-center">
            <i class="fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AuthorizeModal from "@/components/AuthorizeModal.vue";
import { formatAmount } from "@/helpers/number_format.js";
import Swal from "sweetalert2";

export default {
  components: {
    AuthorizeModal,
  },
  props: ["details"],
  data() {
    return {
      acknowledging: false,
      showReceiptDetails: false,
      receiptDetails: {},
    };
  },
  computed: {
    paymentSchedule() {
      return this.details.payment_schedule;
    },
    paymentReceipts() {
      return this.details.payment_receipts;
    },
  },
  methods: {
    formatAmount,
    acknowledgeReturns() {
      this.acknowledging = true;
      this.$store
        .dispatch("annual_returns/acknowledgeReturns", {
          ids: [this.details.id],
        })
        .then((res) => {
          if (res.status != "error") {
            Swal.fire({
              icon: "success",
              title: "Revenue House",
              text: "Annual Returns Acknowledged Successfully!",
            });
            this.$emit("go-back");
          }
        })
        .catch((err) => {
          // console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err,
          });
        })
        .finally(() => {
          this.acknowledging = false;
        });
    },
    viewReceipt(receipt_number) {
      // console.log(receipt_number);

      this.$store
        .dispatch("getPaymentDetails", { receipt_number })
        .then((res) => {
          if (res.status != "error") {
            this.receiptDetails = res.data;
            this.showReceiptDetails = true;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.message || res.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err,
          });
        });
    },
    goBack() {
      this.$emit("go-back");
    },
  },
};
</script>

<style lang="scss" scoped></style>
