import numeral from "numeral";

const formatNumber = (num) => {
  return numeral(num).format("0,0");
};
const formatAmount = (amt) => {
  return numeral(amt).format("0,0.00");
};

export { formatAmount, formatNumber };
